/**
 * @param {String} selector - element to find
 * @param {Element} context [document] - wrapping element
 * @return {Element} -
 */
export function select(selector, context = document) {
  return context.querySelector(selector);
}

/**
 * @param {String} selector -  element to find
 * @param {Element} context [document] - wrapping element
 * @return {Array} - array of dom nodes
 */
export function selectAll(selector, context = document) {
  return Array.from(context.querySelectorAll(selector));
}

/**
 * Always retrieves an dom Element from several
 * types of selector inputs
 * @param {String|Element|jQuery} selector
 * @return {Element}
 */
export function getElement(selector, context = document) {
  if (selector instanceof Element) {
    return selector;
  } else if (typeof selector === 'string') {
    return select(selector, context);
  } else if (selector instanceof jQuery) {
    return selector.get(0);
  }

  throw new Error('You must provide a valid query String selector, an HTMLElement or jQuery Object');
}

/**
 * Creates and returns a dom element and it's children
 * based on template string ``
 * @param {String} str
 * @returns {Element}
 */
export const createElement = str => {
  const el = document.createElement('div');
  el.innerHTML = str;
  return el.firstElementChild;
};
